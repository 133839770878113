import React from 'react';
import { Modal, Button } from 'react-bootstrap-v5';

interface IProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  message: string;
  cancelLabel?: string;
  okLabel?: string;
  title?: string;
}

const ConfirmActionModal: React.FC<IProps> = ({
  show,
  handleClose,
  handleConfirm,
  message,
  cancelLabel = 'Cancel',
  okLabel = 'Proceed',
  title = 'Confirm Action'
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type='button' className='btn-close' onClick={handleClose}></button>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {cancelLabel}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {okLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmActionModal;
