import React, { useEffect, useMemo, useState } from 'react'
interface IProps {
  notifySaveChanges: boolean,
  setNotifySaveChanges: (notifySaveChanges: boolean) => void,
  sessionTimeoutWarning: boolean,
  setSessionTimeoutWarning: (sessionTimeoutWarning: boolean) => void
}

export const SessionTimeoutContext = React.createContext<IProps>({
  notifySaveChanges: false,
  setNotifySaveChanges: (notifySaveChanges: boolean) => { },
  sessionTimeoutWarning: false,
  setSessionTimeoutWarning: (sessionTimeoutWarning: boolean) => { }
})

export const SessionTimeoutContextProvider: React.FC = ({ children }) => {

  const [notifySaveChanges, setNotifySaveChanges] = useState(false);
  const [sessionTimeoutWarning, setSessionTimeoutWarning] = useState(false);

  const value: IProps = useMemo(() => {
    return {
      notifySaveChanges,
      setNotifySaveChanges,
      sessionTimeoutWarning,
      setSessionTimeoutWarning
    }
  }, [
    notifySaveChanges,
    setNotifySaveChanges,
    sessionTimeoutWarning,
    setSessionTimeoutWarning
  ]);

  return <SessionTimeoutContext.Provider value={value}>{children}</SessionTimeoutContext.Provider>
}
