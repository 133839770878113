import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PAGE_PERMISSION } from '../modules/common/constant/roles-constant';
import { Page } from '../modules/roles/models/role-model';
import ConfirmActionModal from '../shared/components/ConfirmActionModal';
import { SessionTimeoutContext } from '../../_metronic/layout/core/SessionTimeoutContext';
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { useDispatch, useStore } from 'react-redux';


type Props = {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  permission: PAGE_PERMISSION,
  activePages: Page[]
};

const ActivatedPageRoute: React.FC<Props> = ({
  component: Component,
  permission,
  activePages,
  ...rest
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { sessionTimeoutWarning, setSessionTimeoutWarning } = useContext(SessionTimeoutContext);

  const refreshToken = () => {
    store.dispatch(auth.actions.refreshTokenRequested())
    dispatch(auth.actions.refreshTokenRequested());
  };

  return (

    <Route
      {...rest}
      render={props => {
        var allowed = activePages.some(page => page.name === permission);
        return allowed ? (
          <>
            <ConfirmActionModal
              show={sessionTimeoutWarning}
              handleClose={() => {
                setSessionTimeoutWarning(false)
                dispatch(auth.actions.logout());
              }}
              handleConfirm={() => {
                refreshToken();
                setSessionTimeoutWarning(false)
              }}
              message="Your session has expired. Would you like to continue your session?"
              cancelLabel="No"
              okLabel="Yes"
              title="Session Timeout"
            />
            <Component {...props} />
          </>
        ) : (
          <Redirect to='/error/404' />
        );
      }}
    />
  );
};

export default ActivatedPageRoute;
