import React, {useContext, useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import { SessionTimeoutContext, SessionTimeoutContextProvider } from './core/SessionTimeoutContext'
import { TimeoutProvider } from './core/TimeoutProvider'
import ConfirmActionModal from '../../app/shared/components/ConfirmActionModal'

const MasterLayout: React.FC = ({ children }) => {

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <SessionTimeoutContextProvider>
        <TimeoutProvider>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />
              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <div className='post d-flex flex-column-fluid' id='kt_post'>
                  <Content>{children}</Content>
                </div>
              </div>
            </div>  
          </div>
          <ActivityDrawer />
          <DrawerMessenger />
          <Main />
          <InviteUsers />
          <UpgradePlan />
          <ScrollTop />
        </TimeoutProvider>
      </SessionTimeoutContextProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
