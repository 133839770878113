import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SessionTimeoutContext } from './SessionTimeoutContext';
import { getSessionTimeoutSettings } from '../../../app/modules/pages/settings/api';
import { useEffectOnce } from 'react-use';
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux';


const LOCAL_STORAGE_KEY = 'userActivityTimeout';
const exemptedRoutes = ['/error/404', '/auth'];

export function TimeoutProvider({ children }: { children: ReactNode }): JSX.Element {
  const navigate = useHistory();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const { notifySaveChanges, setSessionTimeoutWarning } = useContext(SessionTimeoutContext);
  const [timeoutDuration, setTimeoutDuration] = useState<number|null>(null);
  const dispatch = useDispatch();

  const resetTimeout = () => {

    if (!timeoutDuration) return;

    clearTimeout(timeoutRef.current);
    const newTimeout = Date.now() + timeoutDuration;
    localStorage.setItem(LOCAL_STORAGE_KEY, newTimeout.toString());
    timeoutRef.current = setTimeout(() => {
      setSessionTimeoutWarning(true);
      dispatch(auth.actions.removeToken());
    }, timeoutDuration);
  };

  useEffectOnce(() => {
    getSessionTimeoutSettings()
      .then((response) => {
        const milliseconds = (response.data.hour * 60 * 60 * 1000) + (response.data.minute * 60 * 1000);
        setTimeoutDuration(milliseconds);
      });
  });

  useEffect(() => {
    if (exemptedRoutes.includes(location.pathname)) return;

    const handleWindowEvents = () => {
      resetTimeout();
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === LOCAL_STORAGE_KEY) {
        const storedTimeout = parseInt(event.newValue || '0', 10);
        const remainingTime = storedTimeout - Date.now();
        clearTimeout(timeoutRef.current);

        if (remainingTime > 0) {
          timeoutRef.current = setTimeout(() => {
            setSessionTimeoutWarning(true);
            dispatch(auth.actions.removeToken());
          }, remainingTime);
        }
      }
    };

    window.addEventListener('mousemove', handleWindowEvents);
    window.addEventListener('keydown', handleWindowEvents);
    window.addEventListener('click', handleWindowEvents);
    window.addEventListener('scroll', handleWindowEvents);

    window.addEventListener('storage', handleStorageChange);

    resetTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('mousemove', handleWindowEvents);
      window.removeEventListener('keydown', handleWindowEvents);
      window.removeEventListener('click', handleWindowEvents);
      window.removeEventListener('scroll', handleWindowEvents);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [navigate, location.pathname, notifySaveChanges, timeoutDuration]);

  return <>{children}</>;
}
